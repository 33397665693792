import React from "react"
import {Link, graphql} from "gatsby"
import Layout from "../components/Layout"
import BlogCards from "../components/BlogCards"
import MobileBlogCards from "../components/MobileBlogCards";
import { Helmet } from "react-helmet"

export default ({data}) => {
    let last = data.allWordpressPost.edges[data.allWordpressPost.edges.length-1];
    return (
        <Layout>
            <Helmet>
                <html lang="en" />
                <title>Blogs List - Cosmoally</title>
                <meta name="description" content="CliniExperts Services Pvt. Ltd. (“Company’) welcomes you to (www.cliniexperts.com). By using our Application, you are agreeing to comply with and be bound by the following"/>

                <meta name="robots"
                      content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
                <meta property="og:locale" content="en_IN"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Blogs List - Cosmoally"/>
                <meta property="og:description"
                      content="CliniExperts Services Pvt. Ltd. (“Company’) welcomes you to (www.cliniexperts.com). By using our Application, you are agreeing to comply with and be bound by the following &hellip; Read More &#8594;"/>
                <meta property="og:url" content="https://cosmoally.com/blogs/"/>
                <meta property="og:site_name" content="Cosmoally"/>
                <meta property="article:modified_time" content="2020-04-15T07:27:25+00:00"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:label1" content="Written by"/>
                <meta name="twitter:data1" content="Cosmoally Team"/>
                <meta name="twitter:label2" content="Est. reading time"/>
                <meta name="twitter:data2" content="8 minutes"/>
                <script type="application/ld+json"
                        className="yoast-schema-graph">
                    {
                        `{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"http://cosmoally.com/#website","url":"http://cosmoally.com/","name":"Cosmoally","description":"CliniExperts Services Pvt. Ltd.","potentialAction":[{"@type":"SearchAction","target":"http://cosmoally.com/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://cosmoally.com/blogs/#webpage","url":"https://cosmoally.com/blogs/","name":"Blogs List - Cosmoally","isPartOf":{"@id":"http://cosmoally.com/#website"},"datePublished":"2020-04-10T17:52:27+00:00","dateModified":"2020-04-15T07:27:25+00:00","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://cosmoally.com/blogs/"]}]}]}`
                    }
                </script>
                <link rel="alternate" type="application/rss+xml" title="Cosmoally &raquo; Feed"
                      href="http://cosmoally.com/feed/"/>
                <link rel="alternate" type="application/rss+xml" title="Cosmoally &raquo; Comments Feed"
                      href="http://cosmoally.com/comments/feed/"/>
            </Helmet>
            <section className="blog-home-background-image">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">

                            <div className={'color-white p-md-5'}>
                                <br/>
                                <Link to={last.node.slug+"/"}>
                                    <h1 class="color-white">{last.node.title}</h1>
                                </Link>
                                <br/>
                                <span>{last.node.date}</span>
                                <span>{last.node.categories.map(cat => (
                                    <>
                                        &nbsp;&nbsp;|&nbsp;&nbsp; <a style={{color:'#fff'}} href={"/category/"+cat.name.toLowerCase()+"/"}>{cat.name}</a>
                                    </>
                                ))}
                                </span>
                                <br/>
                                <br/>
                                <div style={{color:'#fff!important' }} dangerouslySetInnerHTML={{__html: last.node.excerpt.split('</p>')[0]+'</p>'}}/>
                                <br/>
                                <br/>
                                <Link to={last.node.slug+"/"}>
                                    <button class="btn btn-pill color-white" style={{backgroundColor:'#fe27c2'}}>Read More</button>
                                </Link>
                            </div>
                            <div style={{height:'150px',width:'1px'}}> </div>
                        </div>
                        <div className="col-md-3 offset-md-2 ">
                            <div>
                                <Link to={last.node.slug+"/"}>
                                    <img src={last.node.featured_media.localFile.childImageSharp.resolutions.src} alt={''} className={'wp-post-img mt-3'}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="container-fluid desktop_view_layout  d-none d-md-block pb-5" style={{backgroundColor: 'rgb(245 245 245)'}}>
                <div className="container">
                    <br/>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div>
                                <div className="card-group row">
                                    {data.allWordpressPost.edges.reverse().map(({node}) => (
                                        <BlogCards data={node}/>
                                     ))}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="mobile_view_layout  d-block d-md-none" style={{backgroundColor: 'rgb(220 220 220)', width: '100%'}}>
                <div className={'container'}>
                    {data.allWordpressPost.edges.reverse().map(({node}) => (
                        <MobileBlogCards data={node}/>
                    ))}
                </div>
            </div>


        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD-MMM-YYYY")
          categories{
            name
          }
           featured_media {
                localFile {
                  childImageSharp {
                    resolutions(width: 768, height: 768) {
                      src
                      height
                      width
                    }
                }
              }
            }
        }
      }
    }
  }`
